@use "styles/colors";

.link_container {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 1.4rem;
}

.footer {
    padding-top: 6.4rem;
    width: 100%;
    padding-bottom: 6.4rem;
}

.link {
    display: flex;
    font-size: 1.3rem;
}

a.link:hover {
    text-decoration: underline;
}

.copyright {
    padding-top: 1.25rem;
    text-align: center;
    font-size: 1.1rem;
    color: colors.$black-x-light;
}