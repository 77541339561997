@use "../mq";
@use '../colors';

// Globals for the "react-burger-menu" library.
.bm-menu-wrap {
  top: 8.2rem;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 360px;
}

.bm-burger-bars-hover {
  background-color: colors.$primary !important;
  opacity: 1 !important;
}
