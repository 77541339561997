@use "/styles/mq";
@use "/styles/layout";
@use "/styles/colors";
@use "/styles/typo";

.inner_container {
    display: flex;
    flex-direction: row;
}

.title {
    color: colors.$on-neutral-super;

    @extend %font-h3;
}

.link {
    display: flex;
    flex-direction: column;
    padding-right: 4rem;
}

.name {
    padding-top: 1.4rem;
    color: colors.$on-neutral-super;
    max-width: 18.2rem;

    @extend %font-h4;
}

.current_path {
    display: none;
}