@use "/styles/colors";
@use "/styles/layout";
@use "/styles/utils";
@use "/styles/mq";

.site_nav__mobile {
  position: absolute;
}

.site_nav__expanded {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: clamp(1rem, 4%, 3rem);
  white-space: nowrap;
}

.loading {
  background: url("../../assets/loader.gif") no-repeat top left;
  background-size: 7rem;
  height: 10rem;
  width: 100%;
}

.loadingCopy {
  @include utils.screen-reader-only;
}

// OVERRIDES

.menu {
  position: relative;
  background: colors.$snow;
  padding: 2.9rem;

  @include layout.app-layout(true, false);

	@include mq.media('<=mobile') {
		padding-top: .9rem;
	}
}

.itemList {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
