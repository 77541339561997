@use "/styles/mq";
@use "/styles/colors";
@use "/styles/typo";
@use "/styles/layout";
@use "/styles/utils";

.nav_header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	white-space: nowrap;
	column-gap: 3rem;
	padding: 5.2rem 0;
	width: 100%;

	@include mq.media('<=mobile') {
		column-gap: 0;
		padding: 2rem 0;
	}
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
	margin: 0;

	@extend %font-h1;
}

.branding {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: .8rem;
	min-width: 29.1rem;
}

.link {
	@extend %font-large;
	font-size: typo.$font-size-cougar;

	&.selectedLink {
		color: colors.$primary;
		@extend %font-large-semibold;
		font-size: typo.$font-size-cougar;
	}
}

.toggle {
	cursor: pointer;
  border: none;
  background: none;
  display: block;
  margin-left: auto;
	line-height: .1px;
}

.toggle_icon {
  width: 2rem !important;
}

.toggle_text {
  @include utils.screen-reader-only;
}