@use "/styles/mq";
@use "/styles/layout";

.header, .footer {
  @include layout.app-layout(true, false);
}

.footer {
  margin: 8rem 0;

  @include mq.media('<=tablet') {
    margin-top: 3.2rem;
  }
}