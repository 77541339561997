html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 10px;
  /* Makes easier to convert px values into rem values. Examples: 15px = 1.5rem, 30px = 3rem */
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

th {
  font-weight: normal;
}